import React, { useState, useEffect } from 'react';

import Remote from '../../remote';
const remote = new Remote('', {});

const OrganisationIntegrationsTableRow = (props) => {
  const jsonIndent = (data) => {
    try {
      if (data) {
        return JSON.stringify(JSON.parse(data), null, 2);
      }
    } catch (err) {}
    return data;
  };

  const [enabled, setEnabled] = useState(props.organisationIntegration.enabled);
  const [cascade, setCascade] = useState(props.organisationIntegration.cascade);
  const [metadata, setMetadata] = useState(jsonIndent(props.organisationIntegration.metadata));

  const [editMode, setEditMode] = useState(false);

  const remoteUrl = `organisation/${props.organisationIntegration.organisation_id}/integrations/${props.organisationIntegration.integration_id}`;

  const updateIntegration = async () => {
    try {
      const response = await remote.put(remoteUrl, {
        enabled,
        cascade,
        metadata,
      });
      setEditMode(false);
    } catch (err) {
      console.error(err);
    }
    props.reloadState();
  };

  const deleteIntegration = async () => {
    if (!confirm('Are you sure?')) {
      return;
    }
    try {
      const response = await remote.delete(remoteUrl);
      setEditMode(false);
    } catch (err) {
      console.error(err);
    }
    props.reloadState();
  };

  const switchMode = (e) => {
    e.preventDefault();
    setEditMode(e.target.dataset.mode === 'edit');
  };

  return editMode
    ? [
        <tr key={`integration-${props.organisationIntegration.Integration.id}-edit`}>
          <td>{props.organisationIntegration.Integration.name}</td>
          <td>
            <input
              type="checkbox"
              onChange={(e) => {
                setEnabled(e.target.checked);
              }}
              checked={enabled}
            />
          </td>
          <td>
            <input
              type="checkbox"
              onChange={(e) => {
                setCascade(e.target.checked);
              }}
              checked={cascade}
            />
          </td>
          <td>
            <a href="#" onClick={switchMode} data-mode="view">
              Cancel
            </a>
          </td>
        </tr>,
        <tr key={`integration-${props.organisationIntegration.Integration.id}-config`}>
          <td colSpan="4">
            <textarea
              onChange={(e) => {
                setMetadata(jsonIndent(e.target.value));
              }}
              className="form-control"
              rows="3"
            >
              {metadata}
            </textarea>
            <br />
            <button onClick={updateIntegration} className="btn btn-primary">
              Update
            </button>
            <button onClick={deleteIntegration} className="btn btn-danger pull-right">
              Delete
            </button>
          </td>
        </tr>,
      ]
    : [
        <tr key={`integration-${props.organisationIntegration.Integration.id}`}>
          <td>{props.organisationIntegration.Integration.name}</td>
          <td>{enabled ? 'true' : 'false'}</td>
          <td>{cascade ? 'true' : 'false'}</td>
          <td>
            {props.editable ? (
              <a href="#" onClick={switchMode} data-mode="edit">
                Edit
              </a>
            ) : (
              <></>
            )}
          </td>
        </tr>,
        metadata ? (
          <tr key={`integration-${props.organisationIntegration.Integration.id}-meta`}>
            <td colSpan="4" className="text-secondary">
              <pre>{metadata}</pre>
            </td>
          </tr>
        ) : (
          <tr key={`integration-${props.organisationIntegration.Integration.id}-empty`} />
        ),
      ];
};

const OrganisationIntegrationList = (props) => {
  return (
    <table className="table table-sm">
      <thead>
        <tr>
          <th>Name</th>
          <th>Enabled</th>
          <th>Cascade</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.organisationIntegrations.map((organisationIntegration) => {
          return (
            <OrganisationIntegrationsTableRow
              key={organisationIntegration.id}
              reloadState={props.reloadState}
              organisationIntegration={organisationIntegration}
              editable={organisationIntegration.organisation_id === props.organisationId}
            />
          );
        })}
      </tbody>
    </table>
  );
};

const OrganisationIntegrationsPage = (props) => {
  const [organisationIntegrations, setOrganisationIntegration] = useState(props.organisationIntegrations);
  const [integrationIdSelected, setIntegrationIdSelected] = useState(false);

  const reloadState = async () => {
    try {
      const response = await remote.get(`organisation/${props.organisationId}/integrations`);
      setOrganisationIntegration(response.organisationIntegrations);
    } catch (err) {
      console.error(err);
    }
  };

  const addIntegration = async () => {
    try {
      const response = await remote.put(`organisation/${props.organisationId}/integrations/${integrationIdSelected}`, {
        enabled: false,
        cascade: false,
        metadata: null,
      });
      setIntegrationIdSelected();
    } catch (err) {
      console.error(err);
    }
    reloadState();
  };

  const getAvailableIntegrations = () => {
    return props.integrations.filter((integration) => {
      return !organisationIntegrations.some((organisationIntegration) => {
        return (
          organisationIntegration.integration_id === integration.id &&
          organisationIntegration.organisation_id === props.organisationId
        );
      });
    });
  };

  const getOwnOrganisationIntegrations = () => {
    return organisationIntegrations.filter(
      (organisationIntegration) => organisationIntegration.organisation_id === props.organisationId
    );
  };

  const getRootOrganisationIntegrations = () => {
    return organisationIntegrations.filter(
      (organisationIntegration) => organisationIntegration.organisation_id !== props.organisationId
    );
  };

  return (
    <div>
      <div className="panel panel-flat">
        <div className="panel-heading">
          <h5 className="panel-title">Organisation integrations</h5>
        </div>

        <div className="panel-body">
          <OrganisationIntegrationList
            reloadState={reloadState}
            organisationId={props.organisationId}
            organisationIntegrations={getOwnOrganisationIntegrations()}
          />
        </div>
      </div>

      <div className="panel panel-flat">
        <div className="panel-heading">
          <h5 className="panel-title">Add integrations</h5>
        </div>

        <div className="panel-body">
          <div className="form-group">
            <select
              onChange={(e) => {
                setIntegrationIdSelected(e.target.value);
              }}
              className="form-control"
            >
              <option></option>
              {getAvailableIntegrations().map((integration) => {
                const disabled = false;
                return (
                  <option key={integration.id} disabled={disabled} value={integration.id}>
                    {integration.name} ({integration.area}:{integration.key})
                  </option>
                );
              })}
            </select>
          </div>
          <button
            disabled={!integrationIdSelected}
            onClick={addIntegration}
            className="btn btn-primary {!integrationIdSelected ? 'disabled' : ''}"
          >
            Add
          </button>
        </div>
      </div>

      {getRootOrganisationIntegrations().length > 0 && (
        <div className="panel panel-flat">
          <div className="panel-heading">
            <h5 className="panel-title">Cascaded integrations (from root organisation)</h5>
          </div>

          <div className="panel-body">
            <OrganisationIntegrationList
              reloadState={reloadState}
              organisationId={props.organisationId}
              organisationIntegrations={getRootOrganisationIntegrations()}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default OrganisationIntegrationsPage;
