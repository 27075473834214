import React from 'react';
import ReactDOM from 'react-dom';

import OrganisationIntegrationsPage from './OrganisationIntegrations';
import Remote from '../remote';
const remote = new Remote('', {});

const reactRenderOrganisationIntegrations = (domId, data = {}) => {
  const { organisationId, integrations, organisationIntegrations } = data;

  ReactDOM.render(
    <OrganisationIntegrationsPage
      organisationId={organisationId}
      integrations={integrations}
      organisationIntegrations={organisationIntegrations}
    />,
    document.getElementById(domId)
  );
};
window.reactRenderOrganisationIntegrations = reactRenderOrganisationIntegrations;
